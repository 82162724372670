@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
}

.grid-cr {
    grid-template-columns: repeat(auto-fill, 18rem)
}

@media (min-width: 720px) {
    .grid-cr {
        grid-template-columns: repeat(auto-fill, 15rem)
    }
}

@media (min-width: 960px) {
    .grid-cr {
        grid-template-columns: repeat(auto-fill, 20rem)
    }
}